import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  padding: 5rem;
  font-family: 'Arial', sans-serif;
  flex-wrap: wrap;
  padding: 1rem; /* Reduced padding for smaller screens */


  @media (min-width: 768px) {
    flex-direction: row;
    padding: 6rem; /* Original padding for larger screens */
  }

  @media (min-width: 1200px) {
    padding: 7rem;
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the children (title, description, buttons) */
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0 1rem; /* Add horizontal padding here */

  @media (max-width: 767px) {
    order: 3;
    margin-bottom: 2rem;
    padding: 0 1rem; /* Adjust the horizontal padding for small screens */
  }


  @media (min-width: 768px) {
    width: 50%; /* Adjust width as needed */
    margin-bottom: 0;
    order: 1; /* You may want to adjust this if you have more than two elements */
    padding: 0; /* Reset padding for larger screens if needed */

  }
`;



const BiggerHeading = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: 2rem;
  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
  
  @media only screen and (max-width: 600px) {
    .heading {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  @media (min-width: 768px) {
       font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    font-size: 5rem;
  }
  
`;

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  margin-left: 1.5rem;
  font-weight: 300;


  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2; // change order for mobile
  margin-bottom: 2rem; // add margin for space between image and buttons

  @media (max-width: 767px) {
    order: 2;
  }
  @media (min-width: 768px) {
    order: 3;
    margin-bottom: 0;
  }
`;
const PhoneImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 400px;
 

  @media (min-width: 768px) {
    max-width: 500px;
    order: 2;  // This retains the original order for wider screens
  }
  @media (max-width: 767px) {
    order: 2;
  }

  @media (min-width: 576px) {
    max-width: 300px;
  }
`;

const ButtonImages = styled.div`
  display: flex;
  flex-direction: column; /* Change to column to stack buttons vertically by default */
  margin-left: 1rem;
  justify-content: center; /* Center the buttons horizontally */
  align-items: center;
  width: 100%;

  @media (min-width: 1368px) {
    flex-direction: row; /* Change back to row for larger screens to align buttons in a row */
    justify-content: center; /* This will center the buttons on larger screens */
  }
`;


const AppButton = styled(Link)`
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Arial', sans-serif;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #333;
    transform: translateY(-2px);
  }
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

function HeroSection() {
  return (
    <HeroContainer>
      <ContentSection>
        <BiggerHeading>Alecto AI</BiggerHeading>
        <Description>
        Online Identity Infrastructure
        </Description>
        
        <ButtonImages>
        <div> {/* Container for download buttons */}
            <AppButton as="a" href="https://apps.apple.com/us/app/alecto-ai/id1577004341" target="_blank" rel="noopener noreferrer">
              <Icon className="fa-brands fa-apple" /> Download 
            </AppButton>
            <AppButton as="a" href="https://play.google.com/store/apps/details?id=com.alectoreactandroidapp" target="_blank" rel="noopener noreferrer">
              <Icon className="fa-brands fa-android" /> Download 
            </AppButton>
          </div>
          <div> {/* Separate container for the donation button */}
            <AppButton as="a" href="https://buy.stripe.com/8wM15z9GBbhhdxe8ww" target="_blank" rel="noopener noreferrer">
              <Icon className="fa-solid fa-heart" /> Make a Donation to Alecto
            </AppButton>
          </div>
        </ButtonImages>
      </ContentSection>
      <RightSection>
        <PhoneImage src="/images/iphonescreen-4.png" alt="Phone" />
      </RightSection>
    </HeroContainer>
  );
}

export default HeroSection;
