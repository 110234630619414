import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';

import DropdownResources from './Dropdowns/DropdownResources';
import DropdownAbout from './Dropdowns/DropdownAbout';

function Navbar() {
  const [click, setClick] = useState(false);
  const [, setDropdownp] = useState(false);
  const [dropdownr, setDropdownr] = useState(false);
  const [, setDropdowns] = useState(false);
  const [dropdowna, setDropdowna] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterp = () => {
    if (window.innerWidth < 960) {
      setDropdownp(false);
    } else {
      setDropdownp(true);
    }
  };
  const onMouseLeavep = () => {
    if (window.innerWidth < 960) {
      setDropdownp(false);
    } else {
      setDropdownp(false);
    }
  };

  const onMouseEntera = () => {
    if (window.innerWidth < 960) {
      setDropdowna(false);
    } else {
      setDropdowna(true);
    }
  };
  const onMouseLeavea = () => {
    if (window.innerWidth < 960) {
      setDropdowna(false);
    } else {
      setDropdowna(false);
    }
  };

  const onMouseEnterr = () => {
    if (window.innerWidth < 960) {
      setDropdownr(false);
    } else {
      setDropdownr(true);
    }
  };
  const onMouseLeaver = () => {
    if (window.innerWidth < 960) {
      setDropdownr(false);
    } else {
      setDropdownr(false);
    }
  };
  const onMouseEnters = () => {
    if (window.innerWidth < 960) {
      setDropdowns(false);
    } else {
      setDropdowns(true);
    }
  };
  const onMouseLeaves = () => {
    if (window.innerWidth < 960) {
      setDropdowns(false);
    } else {
      setDropdowns(false);
    }
  };

  return (
    <>
      <nav className='navbarba'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu} style={{color: "white",textDecoration:"none"}}>
            <a class="navbar-brand" href="/">
              <div class="logo-image">
                <img src="/images/logo_white.png" alt="p"/>
              </div>
            </a>
          Alecto AI
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu} style={{color: "white",textDecoration:"none"}}>
              Home
            </Link>
          </li>

          
          <li
            className='nav-item'
            onMouseEnter={onMouseEnterr}
            onMouseLeave={onMouseLeaver}
          >
            <Link
              to='/resources'
              className='nav-links'
              onClick={closeMobileMenu}
              style={{color: "white",textDecoration:"none"}}
            >
              Resources <i className='fas fa-caret-down' />
            </Link>
            {dropdownr && <DropdownResources />}
            
          </li>


          <li
            className='nav-item'
            onMouseEnter={onMouseEntera}
            onMouseLeave={onMouseLeavea}
          >
            <Link
              to='/why-alecto'
              className='nav-links'
              onClick={closeMobileMenu}
              style={{color: "white",textDecoration:"none"}}
            >
              About  <i className='fas fa-caret-down' />
            </Link>
            {dropdowna && <DropdownAbout/>}
          
          </li>

            <li className='nav-itema'></li>
            <li className='nav-item'>
            <Link to='/contact-us' className='nav-links' onClick={closeMobileMenu} style={{color: "white",textDecoration:"none"}}>
              Contact 
            </Link>
            </li>
            <li className='nav-itemb'></li>
          
        </ul>
       
      </nav>
    </>
  );
}

export default Navbar;

